import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../UI/Dashboard/DataView'
import Ajax from '../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../UI/Form/FormGroup'
import ErrorField from '../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../UI/Dashboard/DataLoader'
import Button from '../../../UI/Form/Button'
import ContentLayout from '../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import GetState from '../../../../helpers/GetState'
import GetCity from '../../../../helpers/GetCity'
import GetLocation from '../../../../helpers/GetLocation'
import GetSector from '../../../../helpers/GetSector'
import TableContainer from '../../../UI/Dashboard/TableContainer'
import Specification from '../../../UI/Dashboard/AddNewProperty/Specification'
import GetAmenity from '../../../../helpers/GetAmenity'
import GetDeveloper from '../../../../helpers/GetDeveloper'
import RandomId from '../../../../helpers/RandomId'
import Select from 'react-select'
import GetBhk from '../../../../helpers/GetBhk'
import GetFurnishing from '../../../../helpers/GetFurnishing'
import DeleteButton from '../../../UI/Dashboard/DeleteButton'
import PropertyList from '../../../../helpers/PropertyList'

export default function EditProperty() {
    const urlParams = useParams()
    const navigate = useNavigate()
    const [states, setStates] = useState(null)
    const [bhk, setBhk] = useState(null)
    const [furnishing, setFurnishing] = useState(null)
    const [city, setCity] = useState(null)
    const [location, setLocation] = useState(null)
    const [sector, setSector] = useState(null)
    const [amenities, setAmenities] = useState(null)
    const [developers, setDevelopers] = useState(null)
    const [selectedAmenity, setSelectedAmenity] = useState([])
    const [selectedState, setSelectedState] = useState('')
    const [selectedCity, setSelectedCity] = useState('')
    const [selectedLocation, setSelectedLocation] = useState('')
    const [selectedSector, setSelectedSector] = useState('')
    const [productDetail, setProductDetail] = useState(null)
    const [addedAmenity, setaddedAmenity] = useState([])
    const [propertyList, setPropertyList] = useState([])
    const [addedProperty, setAddedProperty] = useState([])
    const [selectedProperty, setSelectedProperty] = useState([])
    const [emiCalculate, setEmiCalculate] = useState(0)
    const [price, setPrice] = useState()
    const [apartmentPhotos,setApartmentPhotos] = useState([])
    const [thumbnail,setThumbnail] = useState()
    const [selectedDeveloper, setSelectedDeveloper] = useState('')
    const [developerDetails, setDeveloperDetail] = useState('')
    const [drone, setDrone] = useState('')
    const [nearByTitle1, setNearByTitle1] = useState('')
    const [nearByTitle2, setNearByTitle2] = useState('')
    const [nearByTitle3, setNearByTitle3] = useState('')
    const [nearBySubTitle1, setNearBySubTitle1] = useState('')
    const [nearBySubTitle2, setNearBySubTitle2] = useState('')
    const [nearBySubTitle3, setNearBySubTitle3] = useState('')
    const [nearByTitleIcon1, setNearByTitleIcon1] = useState('')
    const [nearByTitleIcon2, setNearByTitleIcon2] = useState('')
    const [nearByTitleIcon3, setNearByTitleIcon3] = useState('')
    const [landmark, setLandmark] = useState('')
    const [googleMap, setGoogleMap] = useState('')
    const [projectVideo, setProjectVideo] = useState('')
    const [disableTrue, setDisableTrue] = useState(false)
    const [projectPhotos,setProjectPhotos] = useState([])

    useEffect(()=>{

        (async()=>{
        const stateList = await GetState()
        setStates(stateList)
        })();
        
        (async()=>{
        const bhkList = await GetBhk()
        setBhk(bhkList)
        })();

        (async()=>{
        const furnishingList = await GetFurnishing()
        setFurnishing(furnishingList)
        })();

        (async()=>{
        const amenity = await GetAmenity()
        const amenityArray = []
        for(let i in amenity){
            amenityArray.push({
                label: `${amenity[i].name}`,
                value: amenity[i]._id,
            })
        }
        setAmenities(amenityArray)
        })();

        (async()=>{
            const property = await PropertyList()
            const propertyArray = []
            for(let i in property){
                propertyArray.push({
                    label: `${property[i].name}, ${property[i].unitNumber ? property[i].unitNumber : ''}, ${property[i].area ? property[i].area : ''}, ${property[i].bhk ? property[i].bhk.name : ''}, ${property[i].price ? property[i].price : ''}`,
                    value: property[i]._id,
                })
            }
            setPropertyList(propertyArray)
        })();


        (async()=>{

            const productInfo = await Ajax({
                url: `/product/list?_id=${urlParams.PropertyId}`,
                token: true
            })
            if(productInfo.data.status === 'SUCCESS!'){
                if(productInfo.data.result.productList[0]?.files && productInfo.data.result.productList[0]?.files.length > 0){
                    let projectPhotosId = []
                    productInfo.data.result.productList[0].files?.map((item)=>{
                        projectPhotosId.push(item)
                    })
                    setApartmentPhotos(projectPhotosId)
                }
                if(productInfo.data.result.productList[0]?.thumbnail) setThumbnail(productInfo.data.result.productList[0]?.thumbnail)
                const propertyDetail = productInfo.data.result.productList[0]
                setProjectPhotos(propertyDetail?.projectPhotos ?? [])
                setDrone(propertyDetail?.drone || "")
                setNearByTitle1(propertyDetail?.nearbyTitle1 || "")
                setNearByTitle2(propertyDetail?.nearbyTitle2 || "")
                setNearByTitle3(propertyDetail?.nearbyTitle3 || "")
                setNearBySubTitle1(propertyDetail?.nearbySubTitle1 || "")
                setNearBySubTitle2(propertyDetail?.nearbySubTitle2 || "")
                setNearBySubTitle3(propertyDetail?.nearbySubTitle3 || "")
                setNearByTitleIcon1(propertyDetail?.nearbyIcon1 ? propertyDetail?.nearbyIcon1: "")
                setNearByTitleIcon2(propertyDetail?.nearbyIcon2 ? propertyDetail?.nearbyIcon2 : "")
                setNearByTitleIcon3( propertyDetail?.nearbyIcon3 ? propertyDetail?.nearbyIcon3 : "")
                setProjectVideo(propertyDetail?.youtube.join(",") || "")
                setLandmark(propertyDetail?.landmark ? propertyDetail?.landmark : "")
                setGoogleMap(propertyDetail?.map || "")
                setSelectedState(productInfo.data.result.productList[0]?.state?._id)
                setSelectedCity(productInfo.data.result.productList[0]?.city?._id)
                setSelectedLocation(productInfo.data.result.productList[0]?.locationId?._id)
                setSelectedSector(productInfo.data.result.productList[0]?.sector?._id ?? null)
                setProductDetail(productInfo.data.result.productList[0])
                
                if(productInfo.data.result.productList[0]?.developer?._id){
                    setSelectedDeveloper(productInfo.data.result.productList[0]?.developer?._id)
                     setDisableTrue(true)
                }
                const amenityData = productInfo.data.result.productList[0]?.amenities
                if(amenityData !== null){
                    const addedAmenityArray = []
                    amenityData.forEach((e)=>{
                        addedAmenityArray.push({
                            label: e.name,
                            value: e._id,
                        })
                })
                setaddedAmenity(addedAmenityArray)
                setSelectedAmenity(addedAmenityArray)
                }

                const propertyData = productInfo.data.result.productList[0].relatedProperty
                if(propertyData !== null){
                    const addedPropertyArray = []
                    propertyData.forEach((e)=>{
                        addedPropertyArray.push({
                            label: `${e.name}, ${e.unitNumber ? e.unitNumber : ''}, ${e.area ? e.area : ''}, ${e.bhk ? e.bhk.name : ''},${e.price ? e.price : ''}`,
                            value: e._id,
                        })
                        
                    })
                setAddedProperty(addedPropertyArray)
                setSelectedProperty(addedPropertyArray)
                }
                
                const developerList = await GetDeveloper()
                setDevelopers(developerList)
                const cityFetch = await GetCity(productInfo.data.result.productList[0].state._id)
                setCity(cityFetch)
                const locationFetch = await GetLocation(productInfo.data.result.productList[0].city._id)
                setLocation(locationFetch)
                const sectorFetch = await GetSector(productInfo.data.result.productList[0].locationId._id)
                setSector(sectorFetch)
                
                const addedSpecs =  [] 
                const specficationData = productInfo.data.result.productList[0].specifications
                if(specficationData && specficationData.length > 0){
                    specficationData.forEach((e)=>{
                        addedSpecs.push({
                            key: RandomId(20),
                            name: e.name,
                            value: e.value
                        })
                    })
                    setSpecifications(addedSpecs)
                }
                setEmiCalculate(productInfo.data.result.productList[0].emi ? productInfo.data.result.productList[0].emi : 0)
                setPrice(productInfo.data.result.productList[0].price ? productInfo.data.result.productList[0].price : 0)
            }
        })()
    },[])

    const handleDeveloper = async (e) =>{
        const response = await Ajax({
            url: `/developer?limit=false&_id=${e.target.value}`,
            token: true,
            loader:true
        })
        if(response.data.status == "SUCCESS!"){
            setSelectedDeveloper(response.data.result.docs[0]._id)
            setDeveloperDetail(response.data.result.docs[0])
            setSelectedState(response.data.result.docs[0].state._id)
            const cityFetch = await GetCity(response.data.result.docs[0].state._id)
            setCity(cityFetch)
            setSelectedCity(response.data.result.docs[0].city._id)
            const locationFetch = await GetLocation(response.data.result.docs[0].city._id)
            setLocation(locationFetch)
            setSelectedLocation(response.data.result.docs[0].location._id)
            const sectorFetch = await GetSector(response.data.result.docs[0].location._id)
            setSector(sectorFetch)
            setSelectedSector(response.data.result.docs[0]?.sector?._id ?? null)
            setProjectPhotos(response.data.result.docs[0]?.projectPhotos ?? [])
            const amenityData = response.data.result.docs[0].amenities || []
                    if(amenityData !== null){
                        const addedAmenityArray = []
                        amenityData.forEach((e)=>{
                            addedAmenityArray.push({
                                label: e.name,
                                value: e._id,
                            })
                    })
            setSelectedAmenity(addedAmenityArray)
            }
            setDrone(response.data.result.docs[0].drone || "")
            setNearByTitle1(response.data.result.docs[0].nearbyTitle1 || "")
            setNearByTitle2(response.data.result.docs[0].nearbyTitle2 || "")
            setNearByTitle3(response.data.result.docs[0].nearbyTitle3 || "")
            setNearBySubTitle1(response.data.result.docs[0].nearbySubTitle1 || "")
            setNearBySubTitle2(response.data.result.docs[0].nearbySubTitle2 || "")
            setNearBySubTitle3(response.data.result.docs[0].nearbySubTitle3 || "")
            setNearByTitleIcon1(response.data.result.docs[0]?.nearbyIcon1 ? response.data.result.docs[0]?.nearbyIcon1 : "")
            setNearByTitleIcon2(response.data.result.docs[0]?.nearbyIcon2 ? response.data.result.docs[0]?.nearbyIcon2 : "")
            setNearByTitleIcon3(response.data.result.docs[0]?.nearbyIcon3 ? response.data.result.docs[0]?.nearbyIcon3 : "")
            setProjectVideo(response.data.result.docs[0].youtube || "")
            setLandmark(response.data.result.docs[0].landmark ? response.data.result.docs[0].landmark : "")
            setGoogleMap(response.data.result.docs[0].map || "")
        }else{
            toast.error("Developer doesn't exist")       
        }
    }


    //Add Product
    const productImage = useRef()
    const projectImage = useRef()
    const matterportImage = useRef()
    const thumbnailImage = useRef()
    const nearByIcon1 = useRef()
    const nearByIcon2 = useRef()
    const nearByIcon3 = useRef()
    const updateProduct = async (values, event) => {
        let specName = document.querySelectorAll("[name='specification_title']")
        let specValue = document.querySelectorAll("[name='specification_value']")
        let addedSpecs = []
        let addedAmenity = []
        let addedProperty = []        
        let youtubeLinksArr = []
        let youtubeLinks =  projectVideo.trim().length > 0 ? projectVideo.trim().split(',') : []
        if(youtubeLinks.length > 0){
            for(let i = 0; i < youtubeLinks.length; i++){
                youtubeLinksArr.push(youtubeLinks[i])
            }
        }
        for(let i = 0; i < selectedAmenity.length; i++){
            addedAmenity.push(selectedAmenity[i].value)
        }
        for(let i = 0; i < selectedProperty.length; i++){
            addedProperty.push(selectedProperty[i].value)
        }
        
        const data = {
            name: values.prop_name,
            ...(values.slug && {slug: values.slug}),
            shortDescription: values.shortDescription,
            // description: values.description,
            developer: selectedDeveloper,
            price: price,
            tour3D: values.tour3D,
            map: googleMap,
            apartmentPhotos:apartmentPhotos,
            thumbnail:thumbnail,
            //specifications: addedSpecs,
            // location: values.location,
            // floor: values.floor,
            facing: values.facing,
            area: values.area,
            landmark: landmark || "",
            drone: drone || "",
            bhk: values.bhk,
            state: selectedState,
            city: selectedCity,
            locationId: selectedLocation,
            sector: selectedSector,
            //amenities: addedAmenity,
            hotdeal: values.hotdeal,
            soldout: Number(values.soldout),
            //dateOfToken: values.dateOfToken,
            emi: emiCalculate,
            unitNumber: values.unitNumber,
            bathrooms: values.bathrooms,
            balconies: values.balconies,
            furnishing: values.furnishing,
            unitFloor: values.unitFloor,
            // totalFloor: values.totalFloor,
            parking: values.parking,
            metaTitle: values.metaTitle,
            metaKeyword: values.metaKeyword,
            metaDescription: values.metaDescription,
            showOnLp: values.showOnLp == 1 ? true : false,
            nearbyTitle1: nearByTitle1 || "",
            nearbySubTitle1: nearBySubTitle1 || "",
            nearbyTitle2: nearByTitle2 || "",
            nearbySubTitle2: nearBySubTitle2 || "",
            nearbyTitle3:  nearByTitle3 || "",
            nearbySubTitle3: nearBySubTitle3 || "",
            active: values.active == 1 ? true : false,
            parkingType: values.parkingType,
            // projectPhotos: projectPhotosArr
        }
        console.log(data,"data")
        const formData = new FormData()
        // if(productImage.current.value !== ''){
        //     for(let i = 0; i < productImage.current.files.length; i++){
        //         formData.append("file", productImage.current.files[i])
        //     }
        // }
        if(nearByTitleIcon1 && nearByTitleIcon1._id){
            formData.append("nearbyIcon1", nearByTitleIcon1._id)
        }
        if(nearByTitleIcon2 && nearByTitleIcon2._id){
            formData.append("nearbyIcon2", nearByTitleIcon2._id)
        }
        if(nearByTitleIcon3 && nearByTitleIcon3._id){
            formData.append("nearbyIcon3", nearByTitleIcon3._id)
        }
        if(projectPhotos && projectPhotos.length){
            for(let i = 0; i < projectPhotos.length; i++){
                formData.append("projectPhotos["+i+"]", projectPhotos[i]._id)
            }
        }
        if(projectImage.current.value !== ''){
            for(let i = 0; i < projectImage.current.files.length; i++){
                formData.append("coverPhoto", projectImage.current.files[i])
            }
        }
        // if(nearByIcon1.current.value !== ''){
        //     for(let i = 0; i < nearByIcon1.current.files.length; i++){
        //         formData.append("nearbyIcon1", nearByIcon1.current.files[i])
        //     }
        // }
        // if(nearByIcon2.current.value !== ''){
        //     for(let i = 0; i < nearByIcon2.current.files.length; i++){
        //         formData.append("nearbyIcon2", nearByIcon2.current.files[i])
        //     }
        // }
        // if(nearByIcon3.current.value !== ''){
        //     for(let i = 0; i < nearByIcon3.current.files.length; i++){
        //         formData.append("nearbyIcon3", nearByIcon3.current.files[i])
        //     }
        // }
        if(specName.length < 7 || specValue.length < 7){
            toast.error('Specifications all fields are required',{
                toastId: 'EmptyFields'
            })
            return false
        }else {
            for(let i = 0; i < specName.length; i++){
                // addedSpecs.push({
                //     "name": specName[i].value,
                //     "value": specValue[i].value,
                // })
                if(specValue[i].value != ""){
                    formData.append("specifications["+i+"][name]", specName[i].value)
                    formData.append("specifications["+i+"][value]", specValue[i].value)
                }else{
                    toast.error('Specifications all fields are required',{
                        toastId: 'EmptyFields'
                    })
                    return false
                }
            }
        }
        if(matterportImage.current.value !== ''){
            formData.append("blog", matterportImage.current.files[0])
        }

        // if(thumbnailImage.current.value !== ''){
        //     formData.append("thumbnail", thumbnailImage.current.files[0])
        // }
        
            for(let i = 0; i < selectedAmenity.length; i++){
                formData.append("amenities["+i+"]", selectedAmenity[i].value)
            }
            
            for(let i = 0; i < selectedProperty.length; i++){
                formData.append("relatedProperty["+i+"]", selectedProperty[i].value)
            }

        if(youtubeLinksArr.length === 0){
                formData.append("youtube[0]", [])
        }else{
            for(let i = 0; i < youtubeLinksArr.length; i++){
                formData.append("youtube["+i+"]", youtubeLinksArr[i])
            }
        }
        for(var key in data) {
            //if(data[key] !== "") {
                if(key == "apartmentPhotos"){
                    formData.append(key, JSON.stringify(data[key]))
                }else if(key == 'thumbnail'){
                    if(data[key] !== null) formData.append(key, JSON.stringify(data[key]))
                }else if(key == 'sector'){
                    if(data[key] !== null) formData.append(key, data[key])
                }else{
                    formData.append(key, data[key])
                }
                
            //}
            if(data[key] === "" && key != 'tour3D' &&  key != 'threeDimage' &&  key != 'emi' &&  key != 'nearbyTitle1' &&  key != 'nearbySubTitle1' &&  key != 'nearbyTitle2' &&  key != 'nearbySubTitle2' &&  key != 'nearbyTitle3' &&  key != 'nearbySubTitle3' &&  key != 'drone' && key != "youtube" && key != "landmark" && key != "parkingType" && key != "sector") {
                toast.error(`Some Fields are Empty please check (${key})`,{
                    toastId: 'EmptyFields'
                })
                return false
            }
        }
        const update = await Ajax({
            url: `/product/${urlParams.PropertyId}`,
            method: 'PUT',
            token: true,
            data: formData,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Property updated successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }

    //Seelct State for City
    const chooseState = async(e) => {
        var stateId = e.target.value
        setSelectedState(stateId)
        const cityFetch = await GetCity(stateId)
        setCity(cityFetch)
    }

    //Select City for Location
    const chooseCity = async(e) => {
        var cityId = e.target.value
        setSelectedCity(cityId)
        const locationFetch = await GetLocation(cityId)
        setLocation(locationFetch)
    }

    //Select Location for sector
    const chooseLocation = async(e) => {
        var locationId = e.target.value
        setSelectedLocation(locationId)
        const sectorFetch = await GetSector(locationId)
        setSector(sectorFetch)
    }

    //Add Specification
    const [specifications, setSpecifications] = useState([])
    const addNewSpecification = () => {
        let num = 0
        const data = {
            key: RandomId(20)
        }
        setSpecifications([...specifications, data])
    }

    //Select Amenity
    const handleAmenity = (selectedAmenity) => {
        setSelectedAmenity([...selectedAmenity])
    }
    
    //Select Property
    const handleProperty = (selectedProperty) => {
        setSelectedProperty([...selectedProperty])
    }
    

    //Select Near By
    // const handleNearBy = (selectedNearBy) => {
    //     setSelectedNearBy({selectedNearBy})
    // }

    const handleEmiCalculator = async(e)=>{
        setPrice(e.target.value)
        // let EMI = PMT(9%/12,20*12,(price*1.09*0.85))
        let monthlyInterestRate = 9 / 100 / 12;

            // Convert loan term from years to months
            let totalPayments = 20 * 12;
        
            // Calculate monthly payment
            let monthlyPayment = (e.target.value*1.09*0.85) * (monthlyInterestRate / (1 - Math.pow(1 + monthlyInterestRate, -totalPayments)));

            // Round to two decimal places
            // monthlyPayment = Math.round(monthlyPayment);
        setEmiCalculate(monthlyPayment.toFixed(2))
    }

    const handleImageSize =(e)=>{
        const files = e.target.files || []
        if(files && files.length > 0){
            for(const file of files){
            if(file.size > 1024*1024) {
                toast.error('Image size should not exceed 1 MB',{
                    toastId: 'iconRequired'
                })
                e.target.value = ''
                return false
            }else{
                return true;
            }
        }
        }
    }

    const uploadImage = async (e) =>{
        try{
            const name = e.target.name
            const imageSize = handleImageSize(e)
            if(imageSize){
            const formData = new FormData();
            if(name === "apartmentPhotos" && productImage.current.value !== ''){
                for(let i = 0; i < productImage.current.files.length; i++){
                    formData.append("file", productImage.current.files[i])
                }
            }
            if(name === "thumbnail" && thumbnailImage.current.value !== ''){
                for(let i = 0; i < thumbnailImage.current.files.length; i++){
                    formData.append("thumbnail", thumbnailImage.current.files[i])
                }
            }
            const result = await Ajax({
                url: `/imageUpload`,
                method: 'POST',
                data: formData,
                loader:true,
                token: true
            })
            if(result.data.error){
                toast.error(result.data.error)
            }
            if(result.data.status === 'SUCCESS!'){
                toast.success('Image upload successfully!')
                const uploadFiles = result.data.result.files
                if(name === "apartmentPhotos") setApartmentPhotos([...apartmentPhotos,...uploadFiles])
                else if (name === "thumbnail") setThumbnail(...uploadFiles)
            }
        }
        }catch(error){
            console.log(error)
        }
       
    }

    const handleTagName = async (e,id) =>{
        const tagName = e.target.value
        const name = e.target.name
        if(name == "apartmentPhotos"){
            const projectPhotos = apartmentPhotos.map((item)=>{
                if(item._id == id) {
                    item.tagName = tagName
                }
                return item
            })
            setApartmentPhotos(projectPhotos)
        }
        else if(name == "thumbnail"){
            const updatedThumbnailBanner = { ...thumbnail, tagName: tagName };
            setThumbnail(updatedThumbnailBanner);
        }
    }

    const deleteProductImage = async(id) => {
        const deleteResponse = await Ajax({
            url: `/file/${id}`,
            method: 'DELETE',
            loader:true,
            token: true
        })
        if(deleteResponse.data.error){
            toast.error(deleteResponse.data.error)
        }
        if(deleteResponse.data.status === 'SUCCESS!'){
            if(apartmentPhotos.includes(id)){
                let filterAddedPhotos = apartmentPhotos.filter((item)=>{
                         return item !== id
                   })
                setApartmentPhotos(filterAddedPhotos)
               }
            toast.success('Image deleted successfully!')
            document.querySelector("[data-image-thumb='"+id+"']").remove()
        }
    }
    return (
        <ContentLayout title="Edit Property">
            <Helmet>
                <title>Edit Property</title>
            </Helmet>
            <DataView title="Infomation">
                
                {productDetail === null ? <DataLoader/> : 
                <Formik
                    initialValues={{ 
                        prop_name: productDetail.name ? productDetail.name : '',
                        slug: productDetail.slug ? productDetail.slug : '',
                        shortDescription: productDetail.shortDescription ? productDetail.shortDescription : '',
                        // description: productDetail.description ? productDetail.description : '',
                        // developer: productDetail.developer?._id ? productDetail.developer?._id : '',
                        price: productDetail?.price ?? "",
                        files: '',
                        projectPhotos: '',
                        // drone: productDetail.drone ? productDetail.drone : '',
                        tour3D: productDetail.tour3D !== null ? productDetail.tour3D : '',
                        threeDimage: '',
                        map: productDetail?.map ?? "",
                        specifications: '',
                        location: productDetail&&productDetail?.location,
                        youtube: (productDetail && productDetail.youtube && productDetail.youtube.length > 0) ? productDetail.youtube.toString() : '',
                        floor: productDetail?.floor ?? "",
                        facing: productDetail?.facing ?? "",
                        area: productDetail?.area ?? "",
                        landmark: productDetail?.landmark ?? "",
                        bhk: productDetail.bhk?._id ?? "",
                        amenities: '',
                        hotdeal: productDetail.hotdeal,
                        soldout: productDetail.soldout,
                        //dateOfToken: productDetail.dateOfToken,
                        emi: productDetail.emi ? productDetail.emi : '',
                        unitNumber: productDetail.unitNumber ? productDetail.unitNumber : '',
                        bathrooms: productDetail.bathrooms ? productDetail.bathrooms : '',
                        balconies: productDetail.balconies ? productDetail.balconies : '',
                        furnishing: productDetail.furnishing ? productDetail.furnishing._id : '',
                        unitFloor: productDetail.unitFloor ? productDetail.unitFloor : '',
                        // totalFloor: productDetail.totalFloor ? productDetail.totalFloor : '',
                        parking: productDetail.parking ? productDetail.parking : '',
                        metaTitle: productDetail?.metaTitle ?? "",
                        metaKeyword: productDetail?.metaKeyword ?? "",
                        metaDescription: productDetail?.metaDescription ?? "",
                        showOnLp: productDetail.showOnLp === true ? 1 : 0,
                        nearbyTitle1: productDetail.nearbyTitle1 ? productDetail.nearbyTitle1 : '',
                        nearbySubTitle1: productDetail.nearbySubTitle1 ? productDetail.nearbySubTitle1 : '',
                        nearbyTitle2: productDetail.nearbyTitle2 ? productDetail.nearbyTitle2 : '',
                        nearbySubTitle2: productDetail.nearbySubTitle2 ? productDetail.nearbySubTitle2 : '',
                        nearbyTitle3: productDetail.nearbyTitle3 ? productDetail.nearbyTitle3 : '',
                        nearbySubTitle3: productDetail.nearbySubTitle3 ? productDetail.nearbySubTitle3 : '',
                        active: productDetail.active === true ? 1 : 0,
                        parkingType: productDetail.parkingType ? productDetail.parkingType : ""
                    }}
                   
                    // validationSchema={Yup.object({
                    //     process_title: Yup.string()
                    //     .required('Field is Required'),
                    //     process_description: Yup.string()
                    //     .required('Field is Required'),
                    //     sort_order: Yup.string()
                    //     .required('Field is Required'),
                    //     type: Yup.string()
                    //     .required('Field is Required')
                    // })}
                    onSubmit={(values, event) => {
                        updateProduct(values, event)
                    }}
                >
                <Form>
                    <ul className="nav nav-tabs">
                        <li className="nav-item"><a href="#tab-info" data-bs-toggle="tab" className="nav-link active">Product Info</a></li>
                        <li className="nav-item"><a href="#tab-meta-info" data-bs-toggle="tab" className="nav-link">Meta Info</a></li>
                        <li className="nav-item"><a href="#tab-image-map" data-bs-toggle="tab" className="nav-link">Image / Map</a></li>
                        <li className="nav-item"><a href="#tab-features" data-bs-toggle="tab" className="nav-link">Features</a></li>
                        <li className="nav-item"><a href="#tab-location" data-bs-toggle="tab" className="nav-link">Location</a></li>
                        <li className="nav-item"><a href="#tab-near-buy" data-bs-toggle="tab" className="nav-link">Near By</a></li>
                        <li className="nav-item"><a href="#tab-related-property" data-bs-toggle="tab" className="nav-link">Related Property</a></li>
                        <li className="nav-item"><a href="#tab-specification" data-bs-toggle="tab" className="nav-link">Specification</a></li>
                        <li className="nav-item"><a href="#tab-amenity-nearby" data-bs-toggle="tab" className="nav-link">Amenity</a></li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane active" id="tab-info">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <FormGroup label="Developer" required>
                                        <Field as="select" type="string" name="developer" className='form-select' onChange={handleDeveloper} value={selectedDeveloper} disabled={disableTrue}>
                                            <option value="" >Select</option>
                                            {developers === null ? <option disabled>Loading</option> : developers.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </Field>
                                        <ErrorField><ErrorMessage name="developer"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-6'>
                                <FormGroup label="Property Name" required>
                                <Field placeholder='Property Name' name="prop_name" className='form-control' />
                                <ErrorField><ErrorMessage name="name"/></ErrorField>
                                </FormGroup>
                                </div>
                            </div>
                            
                            <FormGroup label="Sort Description" required>
                                <Field as="textarea" placeholder='Sort Description' name="shortDescription" className='form-control' />
                                <ErrorField><ErrorMessage name="shortDescription"/></ErrorField>
                            </FormGroup>
                            {/* <FormGroup label="Description" required>
                                <Field as="textarea" placeholder='Description' name="description" className='form-control' />
                                <ErrorField><ErrorMessage name="description"/></ErrorField>
                            </FormGroup> */}
                            <div className='row'>
                                <div className='col-md-6'>
                                    <FormGroup label="Price" required>
                                        <Field type="number" name="price" onChange={handleEmiCalculator} value={price} className='form-control' />
                                        <ErrorField><ErrorMessage name="price"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="EMI">
                                        <Field name="emi"  value={emiCalculate} className='form-control'/>
                                        <ErrorField><ErrorMessage name="emi"/></ErrorField>
                                    </FormGroup>
                                </div>
                                
                                <div className='col-md-4'>
                                    <FormGroup label="Soldout" required>
                                        <Field as="select" name="soldout" className='form-select'>
                                            <option value={0}>No</option>
                                            <option value={1}>Yes</option>
                                        </Field>
                                        <ErrorField><ErrorMessage name="soldout"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Hot Deals" required>
                                        <Field as="select" name="hotdeal" className='form-select'>
                                            <option value={1}>Yes</option>
                                            <option value={0}>No</option>
                                        </Field>
                                        <ErrorField><ErrorMessage name="hotdeal"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Show on Homefest Page">
                                        <Field as="select" name="showOnLp" className='form-select'>
                                            <option value={1}>Yes</option>
                                            <option value={0}>No</option>
                                        </Field>
                                        <ErrorField><ErrorMessage name="showOnLp"/></ErrorField>
                                    </FormGroup>
                                </div>
                            </div>
                            <FormGroup label="Active" required>
                                <Field as="select" type="number" name="active" className='form-select'>
                                    <option value={1}>Enable</option>
                                    <option value={0}>Disable</option>
                                </Field>
                                <ErrorField><ErrorMessage name="active"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className="tab-pane" id="tab-meta-info">
                            <FormGroup label="Meta Title" required>
                                <Field placeholder='Meta Title' name="metaTitle" className='form-control' />
                                <ErrorField><ErrorMessage name="metaTitle"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Meta Description" required>
                                <Field as="textarea" placeholder='Meta Description' name="metaDescription" className='form-control' />
                                <ErrorField><ErrorMessage name="metaDescription"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Meta Keyword" required>
                                <Field as="textarea" placeholder='Meta Keyword' name="metaKeyword" className='form-control' />
                                <ErrorField><ErrorMessage name="metaKeyword"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Product Url (Optional)">
                                <Field placeholder='Product Url' name="slug" className='form-control' />
                                <ErrorField><ErrorMessage name="slug"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className="tab-pane" id="tab-image-map">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <FormGroup label="Thumbnail Image" required>
                                        <input type="file" name="thumbnail" onChange={uploadImage} ref={thumbnailImage} className='form-control' accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="files"/></ErrorField>
                                    </FormGroup>
                                    <div className='image-thumbs mb-3 gap-8'>
                                    {thumbnail && 
                                    <div className='single-thumb'>
                                    <img src={thumbnail.path} width={97} height={67} /><br/>
                                    <input type='text' value={thumbnail.tagName} onChange={((event)=>handleTagName(event,thumbnail._id))} name="thumbnail" style={{width:"115px",marginTop:"6px", borderRadius:"2px", border:"1px solid"}}/>
                                    </div>
                                    }
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Apartment Photos" required>
                                        <input type="file" name="apartmentPhotos" onChange={uploadImage} ref={productImage} className='form-control' multiple accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="files"/></ErrorField>
                                    </FormGroup>
                                    {apartmentPhotos.length > 0 && 
                                    <div className='image-thumbs mb-3 gap-8'>
                                        {apartmentPhotos.map((e)=>(
                                            <div key={e._id} className='single-thumb' data-image-thumb={e._id}>
                                                <button type="button" onClick={()=>deleteProductImage(e._id)} className="btn btn-danger">
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                                <img src={e.path} width={97} height={67} /><br/>
                                                <input type='text' value={e.tagName} onChange={((event)=>handleTagName(event,e._id))} name="apartmentPhotos" style={{width:"115px",marginTop:"6px", borderRadius:"2px", border:"1px solid"}}/>
                                            </div>
                                        ))}
                                    </div> }
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Project Photos" required>
                                        <input type="file" disabled="disabled" onChange={handleImageSize} name="projectPhotos" ref={projectImage} className='form-control' multiple accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="projectPhotos"/></ErrorField>
                                    </FormGroup>
                                    {projectPhotos.length > 0 && 
                                    <div className='image-thumbs mb-3'>
                                        {projectPhotos.map((e)=>(
                                            <div key={e._id} className='single-thumb' data-image-thumb={e._id}>
                                                {/* <button type="button" onClick={()=>deleteProductImage(e._id)} className="btn btn-danger">
                                                    <i className="fas fa-trash"></i>
                                                </button> */}
                                                <img src={e.path} width={97} height={67} /><br/>
                                                <input type='text' disabled value={e.tagName} name="projectPhotos" style={{width:"115px",marginTop:"6px", borderRadius:"2px", border:"1px solid"}}/>
                                            </div>
                                        ))}
                                    </div> }
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Drone Video (Youtube Id)" >
                                        <Field placeholder="id" name="drone" disabled="disabled" value={drone} className='form-control' />
                                        <ErrorField><ErrorMessage name="drone"/></ErrorField>
                                    </FormGroup>
                                </div>
                            </div>
                            <FormGroup label="Project Videos (Youtube Id)" >
                                <Field as="textarea" placeholder="id1,id2" value={projectVideo} name="youtube" disabled="disabled"className='form-control' />
                                <ErrorField><ErrorMessage name="youtube"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Google Map (Iframe)" required>
                                <Field as="textarea" placeholder="Google Map (Iframe)" value
                                ={googleMap} disabled="disabled" name="map" className='form-control' />
                                <ErrorField><ErrorMessage name="map"/></ErrorField>
                            </FormGroup>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <FormGroup label="Matterport Url">
                                        <Field placeholder="Matterport Url" name="tour3D" className='form-control' />
                                        <ErrorField><ErrorMessage name="tour3D"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Matterport Image">
                                        <input type="file" onChange={handleImageSize} name="threeDimage" ref={matterportImage} className='form-control' accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="threeDimage"/></ErrorField>
                                    </FormGroup>
                                    {productDetail.threeDimage && 
                                    <div className='image-thumbs mb-3'>
                                    <div key={productDetail.threeDimage._id} className='single-thumb' data-image-thumb={productDetail.threeDimage._id}>
                                    <button type="button" onClick={()=>deleteProductImage(productDetail.threeDimage._id)} className="btn btn-danger">
                                    <i className="fas fa-trash"></i>
                                     </button>
                                    <img src={productDetail.threeDimage.path} width={80} /> 
                                </div>
                                </div>
                                    
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-features">
                            <div className='row'>
                                {/* <div className='col-md-4'>
                                    <FormGroup label="Location" required>
                                        <Field name="location" placeholder="Location" className='form-control'/>
                                        <ErrorField><ErrorMessage name="location"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Floor" required>
                                        <Field name="floor" placeholder="Floor" className='form-control'/>
                                        <ErrorField><ErrorMessage name="floor"/></ErrorField>
                                    </FormGroup>
                                </div> */}
                                <div className='col-md-4'>
                                    <FormGroup label="Facing" required>
                                        <Field name="facing" placeholder="Facing" className='form-control'/>
                                        <ErrorField><ErrorMessage name="facing"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Area" required>
                                        <Field name="area" placeholder="Area" className='form-control'/>
                                        <ErrorField><ErrorMessage name="area"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Landmark" >
                                        <Field name="landmark" placeholder="Landmark" disabled value={landmark}className='form-control'/>
                                        <ErrorField><ErrorMessage name="landmark"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="BHK" required>
                                        <Field as="select" name="bhk" className='form-select'>
                                            <option value="" disabled>Select</option>
                                            {bhk === null ? <option disabled>Loading</option> : bhk.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </Field>
                                        <ErrorField><ErrorMessage name="bhk"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Unit Number" required>
                                        <Field name="unitNumber" placeholder="Unit Number" className='form-control'/>
                                        <ErrorField><ErrorMessage name="unitNumber"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Bathroom" required>
                                        <Field name="bathrooms" placeholder="Bathroom" className='form-control'/>
                                        <ErrorField><ErrorMessage name="bathrooms"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Balcony" required>
                                        <Field name="balconies" placeholder="Balcony" className='form-control'/>
                                        <ErrorField><ErrorMessage name="balconies"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Furnishing" required>
                                        <Field as="select" name="furnishing" className='form-select'>
                                            <option value="" disabled>Select</option>
                                            {furnishing === null ? <option disabled>Loading</option> : furnishing.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </Field>
                                        <ErrorField><ErrorMessage name="furnishing"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Unit Floor" required>
                                        <Field name="unitFloor" placeholder="Unit Floor" className='form-control'/>
                                        <ErrorField><ErrorMessage name="unitFloor"/></ErrorField>
                                    </FormGroup>
                                </div>
                                {/* <div className='col-md-4'>
                                    <FormGroup label="Total Floor" required>
                                        <Field name="totalFloor" placeholder="Total Floor" className='form-control'/>
                                        <ErrorField><ErrorMessage name="totalFloor"/></ErrorField>
                                    </FormGroup>
                                </div> */}
                                <div className='col-md-4'>
                                    <FormGroup label="Parking" required>
                                        <Field name="parking" placeholder="Parking" className='form-control'/>
                                        <ErrorField><ErrorMessage name="parking"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Parking type">
                                        <Field as="select" name="parkingType" className='form-select'>
                                            <option value="">Select</option>
                                            <option value="Open" >Open</option>
                                            <option value="Closed" >Closed</option>
                                            <option value="Open & Closed" >Open & Closed</option>
                                        </Field>
                                        <ErrorField><ErrorMessage name="parkingType"/></ErrorField>
                                    </FormGroup>
                                </div>
                                {/* <div className='col-md-4'>
                                    <FormGroup label="Date of Token" required>
                                        <Field type="date" name="dateOfToken" className='form-control'/>
                                        <ErrorField><ErrorMessage name="dateOfToken"/></ErrorField>
                                    </FormGroup>
                                </div> */}
                                {/* <div className='col-md-4'>
                                    <FormGroup label="EMI">
                                        <Field name="emi" className='form-control'/>
                                        <ErrorField><ErrorMessage name="emi"/></ErrorField>
                                    </FormGroup>
                                </div> */}
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-location">
                            <div className='row'>
                                <div className='col-md-3'>
                                    <FormGroup label="State" required>
                                        <select name="state" className='form-select' disabled="disabled" value={selectedState} onChange={chooseState}>
                                            <option value="" disabled>Select</option>
                                            {states === null ? <option disabled>Loading</option> : states.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="state"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup label="City" required>
                                        <select name="city" value={selectedCity} className='form-select' disabled="disabled" onChange={chooseCity}>
                                            <option value="" disabled>Select</option>
                                            {city === null ? <option disabled>Select State</option> : city.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="city"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup label="Location" required>
                                        <select name="locationId" value={selectedLocation} className='form-select' disabled="disabled" onChange={chooseLocation}>
                                            <option value="" disabled>Select</option>
                                            {location == null ? <option disabled>Loading</option> : location.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="locationId"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup label="Sector" >
                                        <select name="sector" value={selectedSector} onChange={(e)=>setSelectedSector(e.target.value)} className='form-select' disabled="disabled">
                                            <option value="">None</option>
                                            {sector == null ? <option disabled>Loading</option> : sector.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="sector"/></ErrorField>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-near-buy">
                            <div className='row'>
                                <div className='col-md-3'>
                                    <FormGroup label="Title">
                                        <Field name="nearbyTitle1" value={nearByTitle1} disabled="disabled" className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbyTitle1"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-7'>
                                    <FormGroup label="Sub Title">
                                        <Field name="nearbySubTitle1" value={nearBySubTitle1} disabled="disabled" className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbySubTitle1"/></ErrorField>
                                    </FormGroup>
                                </div>

                                <div className='col-md-2'>
                                    <FormGroup label="Icon">
                                        <input type="file" onChange={handleImageSize} disabled="disabled" name="nearbyIcon1" ref={nearByIcon1} className='form-control' accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="nearbyIcon1"/></ErrorField>
                                    </FormGroup>
                                    {/* {productDetail.nearbyIcon1 && 
                                    <div className='image-thumbs mb-3'>
                                        <div className='single-thumb' data-image-thumb={productDetail.nearbyIcon1._id}>
                                            <button type="button" onClick={()=>deleteProductImage(productDetail.nearbyIcon1._id)} className="btn btn-danger">
                                                <i className="fas fa-trash"></i>
                                            </button>
                                            <img src={productDetail.nearbyIcon1.path} width={80} />
                                        </div>
                                    </div> 
                                    } */}
                                    {nearByTitleIcon1 && 
                                    <img src={nearByTitleIcon1?.path ?? ""} width={80} /> }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <FormGroup label="Title">
                                        <Field name="nearbyTitle2" value={nearByTitle2} disabled="disabled" className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbyTitle2"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-7'>
                                    <FormGroup label="Sub Title">
                                        <Field name="nearbySubTitle2" value={nearBySubTitle2} disabled="disabled" className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbySubTitle2"/></ErrorField>
                                    </FormGroup>
                                </div>
                               
                                <div className='col-md-2'>
                                    <FormGroup label="Icon">
                                        <input type="file" onChange={handleImageSize} name="nearbyIcon2" disabled="disabled" ref={nearByIcon2} className='form-control' accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="nearbyIcon2"/></ErrorField>
                                    </FormGroup>
                                    {/* {productDetail.nearbyIcon2 && 
                                    <div className='image-thumbs mb-3'>
                                        <div className='single-thumb' data-image-thumb={productDetail.nearbyIcon2._id}>
                                            <button type="button" onClick={()=>deleteProductImage(productDetail.nearbyIcon2._id)} className="btn btn-danger">
                                                <i className="fas fa-trash"></i>
                                            </button>
                                            <img src={productDetail.nearbyIcon2.path} width={80} />
                                        </div>
                                    </div> 
                                    } */}
                                    {nearByTitleIcon2 && 
                                    <img src={nearByTitleIcon2?.path ?? ""} width={80} /> }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <FormGroup label="Title">
                                        <Field name="nearbyTitle3" value={nearByTitle3} className='form-control' disabled="disabled"/>
                                        <ErrorField><ErrorMessage name="nearbyTitle3"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-7'>
                                    <FormGroup label="Sub Title">
                                        <Field name="nearbySubTitle3" className='form-control' value={nearBySubTitle3} disabled="disabled"/>
                                        <ErrorField><ErrorMessage name="nearbySubTitle3"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-2'>
                                    <FormGroup label="Icon">
                                        <input type="file" onChange={handleImageSize} name="nearbyIcon3" ref={nearByIcon3} disabled="disabled" className='form-control' accept='.jpg, .jpeg, .png, .webp'/>
                                        <ErrorField><ErrorMessage name="nearbyIcon3"/></ErrorField>
                                    </FormGroup>
                                    {/* {productDetail.nearbyIcon3 && 
                                    <div className='image-thumbs mb-3'>
                                        <div className='single-thumb' data-image-thumb={productDetail.nearbyIcon3._id}>
                                            <button type="button" onClick={()=>deleteProductImage(productDetail.nearbyIcon3._id)} className="btn btn-danger">
                                                <i className="fas fa-trash"></i>
                                            </button>
                                            <img src={productDetail.nearbyIcon3.path} width={80} />
                                        </div>
                                    </div> 
                                    } */}
                                    {nearByTitleIcon3 && 
                                    <img src={nearByTitleIcon3?.path ?? ""} width={80} /> }
                                </div>
                            </div>
                        </div>
                        <div className='tab-pane' id="tab-related-property">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <FormGroup label="Select Property" required>
                                        <div className='col-md-12'>
                                            {propertyList !== null ?
                                            <Select 
                                                defaultValue={[...addedProperty]}
                                                options={propertyList} 
                                                onChange={handleProperty}
                                                closeMenuOnSelect={false}
                                                isMulti
                                             /> : 'Loading...' }
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-specification">
                            <TableContainer>
                                <thead>
                                    <tr>
                                        <td className="text-start">Title</td>
                                        <td className="text-start">Description</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {specifications.length > 0 && specifications.map((data, index)=>{
                                      return (
                                        <>
                                          {index < 7 ? (
                                            <tr >
                                              <td className="text-start">
                                                <input type="text" name="specification_title" value={data.name} placeholder="Title" className="form-control" />
                                              </td>
                                              <td className="text-start">
                                                <div className="input-group">
                                                  <input type="text" name="specification_value" defaultValue={data.value} placeholder="Value" className="form-control" />
                                                </div>
                                              </td>
                                            </tr>
                                          ) : (
                                            <Specification key={data.key} data={specifications} target={data.key} action={setSpecifications} label={data.name} value={data.value} />
                                          )}
                                        </>
                                      )
                                     })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="2"></td>
                                        <td className="text-end">
                                            <button type="button" className="btn btn-primary" onClick={addNewSpecification}>
                                                <i className="fas fa-plus-circle"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </TableContainer>
                        </div>

                        <div className="tab-pane" id="tab-amenity-nearby">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <FormGroup label="Select Amenity" required>
                                        <div className='col-md-12'>
                                            {amenities !== null ?
                                            <Select 
                                            isDisabled
                                            value={selectedAmenity}
                                            options={amenities}
                                            onChange={handleAmenity}
                                            closeMenuOnSelect={false}
                                            isMulti
                                             /> : 'Loading...' }
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update Product</Button>
                    </div>
                </Form>
                </Formik> }
            </DataView>
        </ContentLayout>
    )
}