import React, { useState, useEffect, useRef } from "react";
import DataView from "../../../../UI/Dashboard/DataView";
import Ajax from "../../../../../helpers/Ajax";
import { useParams, useNavigate } from "react-router-dom";
import FormGroup from "../../../../UI/Form/FormGroup";
import ErrorField from "../../../../UI/Form/ErrorField";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DataLoader from "../../../../UI/Dashboard/DataLoader";
import Button from "../../../../UI/Form/Button";
import ContentLayout from "../../../../Common/ContentLayout";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import JourneyAjax from "../../../../../helpers/JourneyAjax";
import { useSearchParams } from "react-router-dom";

export default function AddNewBrokerLead() {
  const urlParams = useParams();
  const navigate = useNavigate();
  const userValidToken = localStorage.getItem("userToken");
  const dataIcon = useRef();
  const [dataList, setDataList] = useState();
  const [searchParams] = useSearchParams();

  const currentPerPage = 10;

  useEffect(() => {
    const skip =
      searchParams.get("page") === 1
        ? 10
        : searchParams.get("page") * currentPerPage;
    const getResponse = setTimeout(function() {
      (async () => {
        const response = await JourneyAjax({
          url: `/admin/brokers`,
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          "x-user": userValidToken || null,
          token: true,
        });
        if (response.status === 200) {
          setDataList(response.data.data.brokers);
        }
      })();
    }, 100);
    return () => {
      clearInterval(getResponse);
    };
  }, []);

  const addBroker = async (values, event) => {
    const data = {
      name: values.name,
      email: values.email,
      mobile: values.mobile,
      leadType: values.leadType,
      project: values.project,
      comment: values.comment,
      status: "pending",
      brokerId: values.brokerId,
      finalInvoice:{}
    };
    if(values.isAmaSigned){
      data.isAmaSigned = values.isAmaSigned === "true"?true:false
      if(data.isAmaSigned){
        data.firstTransactionInvoice = {
          amount: values.firstAmount,
          date:new Date(values.firstDate).toISOString()
        }
      }
    }
    if (values.invoiceAmount) data.finalInvoice.amount = values.invoiceAmount;
    if (values.invoiceDate)
    data.finalInvoice.date = new Date(values.invoiceDate).toISOString();
    // if (values.paymentStatus) data.paymentStatus = values.paymentStatus;
  

    const update = await JourneyAjax({
      url: `/brokers/admin/${values.brokerId}/broker-leads`,
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      "x-user": userValidToken || null,
      method: "POST",
      data: data,
      token: true,
    });
    if (update.data.error) {
      toast.error("Something Went Wrong Please Check");
    }
    if (update.status === 200) {
      toast.success("Broker Lead Added Successfully");
      setTimeout(function() {
        navigate("/admin/property-journey/broker-lead");
      }, 500);
    }
  };
  return (
    <ContentLayout title="Add Broker Lead">
      <Helmet>
        <title>Add New Lead</title>
      </Helmet>
      <DataView title="Infomation">
        <Formik
          initialValues={{
            name: "",
            email: "",
            mobile: "",
            leadType: "",
            comment: "",
            project: "",
            invoiceLink: null,
            invoiceAmount: null,
            invoiceDate: null,
            isAmaSigned:false,
            firstInvoice:null,
            firstAmount:null,
            // paymentStatus: "pending",
            status: "pending",
            brokerId: "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Lead Name is required"),
            email: Yup.string()
              .email("Invalid email")
              .nullable(),
            mobile: Yup.string().required("Mobile Number is required"),
            leadType: Yup.string().required("Lead Type is required"),
            project: Yup.string().required("Project is required"),
            brokerId: Yup.string().required("Broker is required"),
            comment: Yup.string().nullable(""),
            isAmaSigned: Yup.boolean().required("AMA status is required"),
            invoiceAmount: Yup.number()
              .typeError("Invoice Amount must be a number")
              .nullable(),
            invoiceDate: Yup.date().nullable(),
            // paymentStatus: Yup.string().nullable(),
            status: Yup.string().required("Status is required"),
          })}
          onSubmit={(values, event) => {
            addBroker(values, event);
          }}
        >
          {({ setFieldValue, values }) => ( <Form>
            <div className="row">
              <div className="col-md-6">
                <FormGroup label="Lead Name" required>
                  <Field
                    placeholder="Lead Name"
                    name="name"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="name" />
                  </ErrorField>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup label="Email">
                  <Field
                    placeholder="Email"
                    type="email"
                    name="email"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="email" />
                  </ErrorField>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup label="Mobile" required>
                  <Field
                    placeholder="Mobile Number"
                    type="string"
                    name="mobile"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="mobile" />
                  </ErrorField>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup label="leadType" required>
                  <Field as="select" name="leadType" className="form-select">
                    <option value="">Select</option>
                    <option value="buyer">Buyer</option>
                    <option value="seller">Seller</option>
                  </Field>
                  <ErrorField>
                    <ErrorMessage name="leadType" />
                  </ErrorField>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup label="Project" required>
                  <Field
                    placeholder="Project"
                    name="project"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="project" />
                  </ErrorField>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup label="Broker" required>
                  <Field as="select" name="brokerId" className="form-select">
                    <option value={""}>Select</option>
                    {dataList &&
                      dataList.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </Field>
                  <ErrorField>
                    <ErrorMessage name="brokerId" />
                  </ErrorField>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup label="Comment">
                  <Field
                    placeholder="Comment"
                    name="comment"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="comment" />
                  </ErrorField>
                </FormGroup>
              </div>

              {/* <div className="col-md-6">
                <FormGroup label="Invoice Link">
                  <Field
                    placeholder="Invoice Link"
                    name="invoiceLink"
                    className="form-control"
                    disabled = {true}
                  />
                  <ErrorField>
                    <ErrorMessage name="invoiceLink" />
                  </ErrorField>
                </FormGroup>
              </div> */}
              <div className="col-md-6">
                <FormGroup label="AMA Signed" required>
                  <Field as="select" name="isAmaSigned" className="form-select">
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </Field>
                  <ErrorField>
                    <ErrorMessage name="isAmaSigned" />
                  </ErrorField>
                </FormGroup>
              </div>
              <div>
              {
                ((values.isAmaSigned==="true" || values.isAmaSigned===true) &&(values.leadType==="seller"))?<div className="col-md-12 mt-3">
                <h3>First Transaction Invoice</h3>
                
                <div className="row">
                        <div className="col-md-6">
                          <FormGroup label="Amount">
                            <Field
                              type="number"
                              placeholder="amount"
                              name="firstAmount"
                              className="form-control"
                            />
                            <ErrorField>
                              <ErrorMessage name="firstAmount" />
                            </ErrorField>
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup label="Date" required>
                            <Field
                              type="date"
                              placeholder="Date"
                              name="firstDate"
                              className="form-control"
                            />
                            <ErrorField>
                              <ErrorMessage name="firstDate" />
                            </ErrorField>
                          </FormGroup>
                        </div>
                        </div>
                </div>:null
              }
              </div>
              <div className="col-md-6">
                <FormGroup label="Invoice Amount">
                  <Field
                    type="number"
                    placeholder="Invoice Amount"
                    name="invoiceAmount"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="invoiceAmount" />
                  </ErrorField>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup label="Invoice Date">
                  <Field
                    type="date"
                    placeholder="Invoice Date"
                    name="invoiceDate"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="invoiceDate" />
                  </ErrorField>
                </FormGroup>
              </div>
              {/* <div className="col-md-6">
                <FormGroup label="Payment Status">
                  <Field
                    as="select"
                    name="paymentStatus"
                    className="form-select"
                  >
                    <option value="pending">Pending</option>
                    <option value="completed">Completed</option>
                  </Field>
                  <ErrorField>
                    <ErrorMessage name="paymentStatus" />
                  </ErrorField>
                </FormGroup>
              </div> */}
              <div className="col-md-6">
                <FormGroup label="Status"  required>
                  <Field as="select" name="status" className="form-select">
                  <option value="">Select</option>
                    <option value="Pending">Pending</option>
                    <option value="completed">Completed</option>
                  </Field>
                  <ErrorField>
                    <ErrorMessage name="status" />
                  </ErrorField>
                </FormGroup>
              </div>
            </div>

            <div className="col-md-2 d-grid">
              <Button type="submit">Add</Button>
            </div>
          </Form>)}
         
        </Formik>
      </DataView>
    </ContentLayout>
  );
}
